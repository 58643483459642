import {StrictMode} from 'react'
import ReactDOM from 'react-dom/client'
import {App} from './App'
import {InstallPromptListener} from './pages/Dashboard/components/Navigation/InstallPromptListener'
import './utils/stats'

if (!window.verticaltopo) {
  window.verticaltopo = {}
}
window.addEventListener('beforeinstallprompt', (event) => {
  event.preventDefault()
  window.verticaltopo.beforeinstallprompt = event
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <StrictMode>
    {/* The PromptListener MUST be registered has soon as possible Because the event `beforeinstallprompt` is sent once at startup*/}
    <InstallPromptListener/>
    <App/>
  </StrictMode>
)
