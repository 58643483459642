import {lazy, memo} from 'react'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  useParams,
} from 'react-router-dom'
import {QueryParamProvider} from 'use-query-params'
import {ReactRouter6Adapter} from 'use-query-params/adapters/react-router-6'
import {I18n} from './components/I18n'
import {getSpotUrl} from './hooks/useSpotNavigate'
import {CurrentSpotIdListener} from './pages/Dashboard/components/Navigation/CurrentSpotIdListener'

const AdminBillingPage = lazy(async () => ({default: (await import('./pages/AdminBilling/AdminBillingPage')).AdminBillingPage}))
const AdminColorCreate = lazy(async () => ({default: (await import('./pages/AdminColor/AdminColorCreate')).AdminColorCreate}))
const AdminColorEdit = lazy(async () => ({default: (await import('./pages/AdminColor/AdminColorEdit')).AdminColorEdit}))
const AdminColorList = lazy(async () => ({default: (await import('./pages/AdminColor/AdminColorList')).AdminColorList}))
const AdminCommentEdit = lazy(async () => ({default: (await import('./pages/AdminComment/AdminCommentEdit')).AdminCommentEdit}))
const AdminCommentList = lazy(async () => ({default: (await import('./pages/AdminComment/AdminCommentList')).AdminCommentList}))
const AdminLaneCreate = lazy(async () => ({default: (await import('./pages/AdminLane/AdminLaneCreate')).AdminLaneCreate}))
const AdminLaneEdit = lazy(async () => ({default: (await import('./pages/AdminLane/AdminLaneEdit')).AdminLaneEdit}))
const AdminLaneList = lazy(async () => ({default: (await import('./pages/AdminLane/AdminLaneList')).AdminLaneList}))
const AdminMembershipCreate = lazy(async () => ({default: (await import('./pages/AdminMembership/AdminMembershipCreate')).AdminMembershipCreate}))
const AdminMembershipEdit = lazy(async () => ({default: (await import('./pages/AdminMembership/AdminMembershipEdit')).AdminMembershipEdit}))
const AdminMembershipList = lazy(async () => ({default: (await import('./pages/AdminMembership/AdminMembershipList')).AdminMembershipList}))
const AdminRouteCreate = lazy(async () => ({default: (await import('./pages/AdminRoute/AdminRouteCreate')).AdminRouteCreate}))
const AdminRouteEdit = lazy(async () => ({default: (await import('./pages/AdminRoute/AdminRouteEdit')).AdminRouteEdit}))
const AdminRouteList = lazy(async () => ({default: (await import('./pages/AdminRoute/AdminRouteList')).AdminRouteList}))
const AdminRouteReport = lazy(async () => ({default: (await import('./pages/AdminRoute/AdminRouteReport')).AdminRouteReport}))
const AdminSectorCreate = lazy(async () => ({default: (await import('./pages/AdminSector/AdminSectorCreate')).AdminSectorCreate}))
const AdminSectorEdit = lazy(async () => ({default: (await import('./pages/AdminSector/AdminSectorEdit')).AdminSectorEdit}))
const AdminSectorList = lazy(async () => ({default: (await import('./pages/AdminSector/AdminSectorList')).AdminSectorList}))
const AdminSpotCreate = lazy(async () => ({default: (await import('./pages/AdminSpot/AdminSpotCreate')).AdminSpotCreate}))
const AdminSpotEdit = lazy(async () => ({default: (await import('./pages/AdminSpot/AdminSpotEdit')).AdminSpotEdit}))
const AdminSpotList = lazy(async () => ({default: (await import('./pages/AdminSpot/AdminSpotList')).AdminSpotList}))
const AdminSettingsRouter = lazy(async () => ({default: (await import('./pages/AdminSettings/AdminSettingsRouter')).AdminSettingsRouter}))
const SpotPage = lazy(async () => ({default: (await import('./pages/AdminSettings/AdminSettings')).AdminSettings}))
const AdminStats = lazy(async () => ({default: (await import('./pages/AdminStats/AdminStats')).AdminStats}))
const AppInstall = lazy(async () => ({default: (await import('./pages/AppInstall/AppInstall')).AppInstall}))
const BillingCallbackRouter = lazy(async () => ({default: (await import('./pages/BillingCallback/BillingCallbackRouter')).BillingCallbackRouter}))
const BillingSessionRouter = lazy(async () => ({default: (await import('./pages/BillingCallback/BillingSessionRouter')).BillingSessionRouter}))
const AuthLink = lazy(async () => ({default: (await import('./pages/Security/AuthLink')).AuthLink}))
const AuthPopup = lazy(async () => ({default: (await import('./pages/Security/AuthPopup')).AuthPopup}))
const DashboardOutlet = lazy(async () => ({default: (await import('./pages/Dashboard/DashboardOutlet')).DashboardOutlet}))
const ErrorHttp = lazy(async () => ({default: (await import('./pages/Error/ErrorHttp')).ErrorHttp}))
const Landing = lazy(async () => ({default: (await import('./pages/Landing/Landing')).Landing}))
const ResumeRedirect = lazy(async () => ({default: (await import('./pages/Landing/ResumeRedirect')).ResumeRedirect}))
const SpotCreate = lazy(async () => ({default: (await import('./pages/SpotCreate/SpotCreate')).SpotCreate}))
const RouteList = lazy(async () => ({default: (await import('./pages/RouteList/RouteList')).RouteList}))
const RouteView = lazy(async () => ({default: (await import('./pages/RouteView/RouteView')).RouteView}))
const SpotView = lazy(async () => ({default: (await import('./pages/SpotView/SpotView')).SpotView}))
const UserProfile = lazy(async () => ({default: (await import('./pages/UserProfile/UserProfile')).UserProfile}))
const UserView = lazy(async () => ({default: (await import('./pages/UserView/UserView')).UserView}))
const WorkoutList = lazy(async () => ({default: (await import('./pages/WorkoutList/WorkoutList')).WorkoutList}))
const WorkoutCategoryList = lazy(async () => ({default: (await import('./pages/WorkoutCategoryList/WorkoutCategoryList')).WorkoutCategoryList}))
const WorkoutView = lazy(async () => ({default: (await import('./pages/WorkoutView/WorkoutView')).WorkoutView}))

const NavigateToRoute = memo(() => {
  const {spotSlug} = useParams()
  const url = spotSlug ? getSpotUrl(spotSlug, '/routes') : '/'

  return <Navigate to={url} replace/>
})

const RouterOutlet = memo(() => {
  return <QueryParamProvider adapter={ReactRouter6Adapter}>
    <CurrentSpotIdListener/>
    <I18n>
      <Outlet/>
    </I18n>
  </QueryParamProvider>
})

const workout = <Route path="workouts">
  <Route index element={<WorkoutCategoryList/>}/>
  <Route path=":category">
    <Route index element={<WorkoutList/>}/>
    <Route path=":workoutId" element={<WorkoutView/>}/>
  </Route>
</Route>
const profile = <Route path=":userId">
  <Route index path="*" element={<UserProfile/>}/>
</Route>

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/:lang?/" element={<RouterOutlet/>}>
      <Route path="auth/oauth/:provider" element={<AuthPopup/>}/>
      <Route path="spot/:spotSlug/billing/session/*" element={<BillingSessionRouter/>}/>
      <Route path="*" element={<DashboardOutlet/>}>
        <Route index element={<Landing/>}/>
        <Route path="auth/link" element={<AuthLink/>}/>

        <Route path="profile" element={<UserView/>}>
          {profile}
        </Route>
        {workout}

        <Route path="app" element={<AppInstall/>}/>
        <Route path="spot-create" element={<SpotCreate/>}/>
        <Route path="resume" element={<ResumeRedirect/>}/>
        <Route path="spot/:spotSlug" element={<SpotView/>}>
          <Route path="profile" element={<Outlet/>}>
            {profile}
          </Route>
          {workout}

          <Route path="billing/callback/*" element={<BillingCallbackRouter/>}/>
          <Route path="routes" element={<RouteList/>}>
            <Route path=":routeId" element={<RouteView/>}/>
          </Route>
          <Route path="admin">
            <Route path="routes" element={<AdminRouteList/>}>
              <Route path="new" element={<AdminRouteCreate/>}/>
              <Route path="report" element={<AdminRouteReport/>}/>
              <Route path=":routeId" element={<AdminRouteEdit/>}/>
            </Route>
            <Route path="comments" element={<AdminCommentList/>}>
              <Route path=":commentId" element={<AdminCommentEdit/>}/>
            </Route>
            <Route path="stats">
              <Route path=":x">
                <Route path=":y" element={<AdminStats/>}/>
                <Route path="*" element={<Navigate to={'count'}/>}/>
              </Route>
              <Route path="*" element={<Navigate to={'grade/count'}/>}/>
            </Route>
            <Route path="settings" element={<AdminSettingsRouter/>}>
              <Route path="global" element={<SpotPage/>}/>
              <Route path="colors" element={<AdminColorList/>}>
                <Route path="new" element={<AdminColorCreate/>}/>
                <Route path=":colorId" element={<AdminColorEdit/>}/>
              </Route>
              <Route path="sectors" element={<AdminSectorList/>}>
                <Route path="new" element={<AdminSectorCreate/>}/>
                <Route path=":sectorId" element={<AdminSectorEdit/>}/>
              </Route>
              <Route path="spots" element={<AdminSpotList/>}>
                <Route path="new" element={<AdminSpotCreate/>}/>
                <Route path=":spotId" element={<AdminSpotEdit/>}/>
              </Route>
              <Route path="lanes" element={<AdminLaneList/>}>
                <Route path="new" element={<AdminLaneCreate/>}/>
                <Route path=":laneId" element={<AdminLaneEdit/>}/>
              </Route>
              <Route path="memberships" element={<AdminMembershipList/>}>
                <Route path="new" element={<AdminMembershipCreate/>}/>
                <Route path=":membershipId" element={<AdminMembershipEdit/>}/>
              </Route>
              <Route path="billing" element={<AdminBillingPage/>}/>
              <Route path="*" element={<Navigate to="./global" replace/>}/>
            </Route>
            <Route path="*" element={<NavigateToRoute/>}/>
          </Route>
          <Route path="*" element={<NavigateToRoute/>}/>
        </Route>

        <Route path="*" element={<ErrorHttp code={404}/>}/>
      </Route>
    </Route>
  )
)

export const Router = memo(() => {
  return <RouterProvider router={router}/>
})
