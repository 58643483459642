import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {ApiError, CustomError, NetworkError, SilentError, TimeoutError, ViolationsError} from '../utils/ApiError'
import {useEffectEvent} from './useEffectEvent'
import {useNotification} from './useNotification'

type Hook = (err: Error) => void;

export function useErrorHandler(): Hook {
  const {notifyError} = useNotification()
  const {t} = useTranslation()

  const notifications = useMemo(() => ({
    timeout: <div className={'flex-column'}>
      <div>{t('error.http.timeout.title')}</div>
      <div>{t('error.http.timeout.body')}</div>
    </div>,
    network: <div className={'flex-column'}>
      <div>{t('error.http.network.title')}</div>
      <div>{t('error.http.network.body')}</div>
    </div>,
    http400: t('error.http.400'),
    http401: t('error.http.401'),
    http402: t('error.http.402'),
    http403: t('error.http.403'),
    http404: t('error.http.404.title'),
    http422: t('error.http.422'),
    http500: t('error.http.500.title'),
  }), [t])

  return useEffectEvent((err: Error) => {
    if (err instanceof ViolationsError) {
      const errors: string[] = []
      for (const violation of err.violations) {
        const field = violation.propertyPath
        errors.push(field ? `${field}: ${violation.message}` : violation.message)
      }
      notifyError(<div className={'flex-column'}>
          <div>{notifications.http400}</div>
          {errors.map((error, index) => <div key={index}>{'• '}{error}</div>)}
        </div>
      )
    } else if (err instanceof NetworkError) {
      notifyError(notifications.network)
    } else if (err instanceof TimeoutError) {
      notifyError(notifications.timeout)
    } else if (err instanceof ApiError && [404].includes(err.statusCode)) {
      // do nothing, silent 404 errors (happens when deleting an item => conflict with mercure + mutation)
    } else if (err instanceof ApiError && [400, 401, 402, 403, 404, 422, 500].includes(err.statusCode)) {
      notifyError(notifications[`http${err.statusCode as 400 | 401 | 402 | 403 | 404 | 422 | 500}`])
    } else if (err instanceof ApiError && err.statusCode < 500) {
      notifyError(notifications.http400)
    } else if (err instanceof CustomError) {
      notifyError(err.message)
    } else if (err instanceof SilentError) {
      // do nothing
    } else {
      notifyError(notifications.http500)
    }
  })
}
