import type {CommentPartial} from '../@types/entity/Comment'
import {
  commentCreatedEventName,
  commentUpdatedEventName,
  notificationNewReleaseEventName,
  userDisconnectedEventName
} from '../enums/event'

export class UserDisconnectedEvent extends Event {
  constructor() {
    super(userDisconnectedEventName)
  }
}

export class NotificationNewReleaseEvent extends Event {
  constructor() {
    super(notificationNewReleaseEventName)
  }
}

export class CommentCreatedEvent extends Event {
  public readonly comment: CommentPartial

  constructor(comment: CommentPartial) {
    super(commentCreatedEventName)

    this.comment = comment
  }
}

export class CommentUpdatedEvent extends Event {
  public readonly comment: CommentPartial
  public readonly previous: CommentPartial

  constructor(comment: CommentPartial, previous: CommentPartial) {
    super(commentUpdatedEventName)

    this.comment = comment
    this.previous = previous
  }
}
