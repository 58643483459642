import {memo} from 'react'
import logo from './resources/logo.svg'
import './Loading.css'

export const Loading = memo(() => {
  return <div className={'layout'}>
    <img className={'logo'} src={logo} alt={'VerticalTopo logo'}/>
    <div className={'spinner'}/>
  </div>
})
