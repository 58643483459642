import '@fontsource/roboto/latin.css'
import * as Sentry from '@sentry/react'
import {APP_VERSION, SENTRY_DSN} from '../config'

Sentry.init({
  dsn: SENTRY_DSN,
  release: APP_VERSION,
  attachStacktrace: true,
  autoSessionTracking: false,

  // inspired by https://gist.github.com/impressiver/5092952
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    'Can\'t find variable: ZiteReader',
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',

    // Network errors such as going offline or being blocked by a proxy
    'Failed to fetch',

    // see https://github.com/getsentry/sentry-javascript/issues/3440#issuecomment-865857552
    'Non-Error promise rejection captured with value: Object Not Found Matching Id:',

    // Service workers errors
    'Failed to update the ServiceWorker for scope https://verticaltopo.com/ because the registration has been uninstalled since the update was scheduled.',
    'Failed to update a ServiceWorker for scope (\'https://verticaltopo.com/\') with script (\'Unknown\'): Operation has been aborted',

    // Userland errors
    'Expired JWT Token',
  ],
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
    /^file:\/\//i,
    // Firefox extensions
    /^resource:\/\//i,
    // IOS extensions
    /webkit-masked-url/,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    // Ignore Google flakiness
    /\/(gtm|ga|analytics)\.js/i,
  ],
})
